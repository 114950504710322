html,
body {
  font-family: "Mulish", "Segoe UI", Helvetica, Arial, sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  /* 'Roboto', 'Helvetica', sans-serif; */
  height: 100%;
  margin: auto;
}
body {
  background: white;
  margin-right: auto;
  margin-left: auto;
}

header {
  height: 50px;
  background: white;
  /* background-image: linear-gradient(to right, #E9383D , #21B1F3, #4AEAB8);  */
  /* background-image: linear-gradient(to top,rgb(31, 184, 255), rgb(71,67,214)); */
  background-image: linear-gradient(
    to right,
    rgb(159, 209, 123),
    rgb(64, 195, 255),
    rgb(254, 123, 230)
  );
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  color: white;
}

footer {
  display: flex;
  padding: 10px;
  font-size: small;
  color: white;
  background: #333;
  background-image: linear-gradient(
    to right,
    rgb(0, 68, 156),
    rgb(39, 199, 254)
  );
}

/* The Modal (background) */
.modal {
  display: none; /* Hidden by default */
  /* Stay in place :was fixed*/
  position: absolute;
  z-index: 4; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  background-color: rgb(0, 0, 0); /* Fallback color */
  /*background-color: rgba(0,0,0,0.4);*/ /*origina/*/
  background-color: rgba(0, 0, 0, 0.747); /* Black w/ opacity */
  flex-direction: column;
}

/* .modal{
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgb(0 0 0 / 19%);
    text-align: center;
    display: none;
    justify-content: center;
    align-items: center;
    color: #0066ff;
    z-index: 4;
  } */

/* Modal Content */
.modal-content {
  background-color: white;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 50%;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  justify-content: flex-start;
  align-items: center;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 500px;
  font-size: 16px;
  position: relative;
}
.keep-width {
  flex-shrink: 0;
}
/* The Close Button */
.close {
  color: #3d3d3d;
  float: right;
  align-self: flex-end;
  font-size: 28px;
  font-weight: bold;
  opacity: 0.9;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.btnBlue {
  border-radius: 5px;
  width: 90%;
  font-size: 16px;
  color: white;
  background: #21b1f3;
  border: none;
  padding: 12px;
  cursor: pointer;
}

.redBtn {
  border-radius: 5px;
  font-size: 16px;
  color: white;
  background-color: red;
  background-image: linear-gradient(to right, rgb(134, 0, 0), rgb(255, 0, 0));
  border: none;
  padding: 8px;
  width: 90%;
  text-decoration: none;
}

.blueBtn {
  border-radius: 5px;
  font-size: 16px;
  color: white;
  background-color: red;
  background-image: linear-gradient(
    to right,
    rgb(40, 0, 134),
    rgb(0, 110, 255)
  );
  border: none;
  padding: 8px;
  text-decoration: none;
}

.vRedBtn {
  border-radius: 5px;
  font-size: 16px;
  color: white;
  background-color: red;
  background-image: linear-gradient(to right, rgb(96, 0, 134), rgb(255, 0, 34));
  border: none;
  padding: 8px;
  width: 90%;
  text-decoration: none;
}

.orangeRedBtn {
  border-radius: 5px;
  font-size: 16px;
  color: white;
  background-color: rgb(255, 72, 0);
  background-image: linear-gradient(to right, rgb(255, 0, 34), rgb(255, 72, 0));
  border: none;
  padding: 8px;
  width: 90%;
  text-decoration: none;
}

.redBtn:hover {
  opacity: 0.85;
}

.btnBlueSmall {
  border-radius: 5px;
  width: 30px;
  height: auto;
  font-size: 16px;
  color: white;
  background: #21b1f3;
  border: none;
  cursor: pointer;
  padding: 5px;
}
.font-18 {
  font-size: 18px;
}
.rel {
  position: relative;
}
.posRel {
  position: relative;
}
.btnGray {
  border-radius: 5px;
  width: 90%;
  font-size: 16px;
  color: white;
  background: #aaaaaa;
  border: none;
  padding: 12px;
  cursor: pointer;
}
.btn-gray {
  border-radius: 5px;
  font-size: 16px;
  color: white;
  background: #aaaaaa;
  border: none;
  padding: 12px;
  cursor: pointer;
}
.btnBlue:hover,
.btnGray:hover,
.btn-gray:hover {
  /* background: white;
    color: #21B1F3;
    border: 1px solid #21B1F3; */
  opacity: 0.85;
}

.blueText {
  color: #2183f3;
}

.whiteText {
  color: white;
}

.boldText,
.t-bold {
  font-weight: 600;
}
.grayText {
  color: gray;
}
.darkGrayText {
  color: #464646;
}
.pinkRedText {
  color: crimson;
}
#snackbar {
  visibility: hidden;
  min-width: 250px;
  margin-left: -125px;
  background-color: #333;
  color: #fff;
  text-align: center;
  border-radius: 2px;
  padding: 16px;
  position: fixed;
  z-index: 201;
  left: 50%;
  bottom: 30px;
  font-size: 17px;
}

#snackbar.show {
  visibility: visible;
  -webkit-animation: fadein 0.5s, fadeout 0.5s 5.5s;
  animation: fadein 0.5s, fadeout 0.5s 5.5s;
}

#toast {
  visibility: hidden;
  min-width: 250px;
  background-color: rgba(51, 51, 51, 0.829);
  color: #fff;
  text-align: center;
  border-radius: 25px;
  padding: 16px;
  position: fixed;
  z-index: 201;
  left: 50%;
  bottom: 30px;
  font-size: 17px;
}

#toast.show {
  visibility: visible;
  -webkit-animation: fadein 0.5s, fadeout 0.5s 5.5s;
  animation: fadein 0.5s, fadeout 0.5s 5.5s;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 140px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 150%;
  left: 50%;
  margin-left: -75px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

@-webkit-keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 30px;
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 30px;
    opacity: 1;
  }
}

@-webkit-keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }
  to {
    bottom: 0;
    opacity: 0;
  }
}

@keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }
  to {
    bottom: 0;
    opacity: 0;
  }
}

/* #makemojifield{ 
    background-color: white;
    border: solid 1px gainsboro;
    border-bottom: solid 2px gainsboro;
    appearance: none;
    -webkit-appearance: none;
    height: auto;
    resize: none;
    max-height: 70px;
    min-height: 70px;
    min-width: calc(100% - 50px);
    
    border-radius: 10px;
    padding: 3px;
    margin: 4px;
    color: black;
   
    overflow: auto;
    align-self: flex-start;
  
} */

/* #inputAreaContent div{
   flex: 1 1 auto;
  
} */

/* #makemojibutton{
  
  display: block;
  width: 30px;
  margin-right: 6px;
  
}


#makemojifield:empty:before {
  content:'Type here...';
  color:gray
}

#makemojitrendingbar{
  display: none;
} */

.bigText {
  font-size: xx-large;
}

.xFont {
  font-size: x-large;
  text-align: center;
}

.blueInput {
  width: 100%;
  padding: 12px;
  border: 2px solid #21b1f3;
  border-radius: 4px;
  margin: 5px 0;
  opacity: 0.85;
  display: inline-block;
  font-size: 17px;
  line-height: 20px;
  text-decoration: none; /* remove underline from anchors */
  box-sizing: border-box; /*Makes the button and inputs same width*/
}

.blueInput:hover,
.btn-blue:hover,
.btn-lime:hover {
  opacity: 0.85;
}

.greenText {
  color: rgb(0, 140, 255);
  text-shadow: 2px 2px 4px #000000;
}

.roundImg {
  border-radius: 50%;
}

.verticalFlex {
  display: flex;
  flex-direction: column;
}

.roundCorners {
  border-radius: 5px;
}

.horizFlex {
  display: flex;
  flex-direction: row;
}

.topRoundedWhite {
  border-radius: 10px 10px 0 0;
  background-color: white;
}

.roundBlueBtn {
  border: none;
  border-radius: 8px;
  background-color: #21b1f3;
  background-image: linear-gradient(
    to right,
    rgba(6, 158, 243),
    rgb(0 132 212)
  );
  color: white;
  padding: 2px 8px;
  cursor: pointer;
}

.roundGreenBtn {
  border-radius: 10px;
  background-color: rgb(255, 120, 0);
  background-image: linear-gradient(
    to right,
    rgb(255, 108, 0),
    rgb(255, 191, 0)
  );
  color: white;
}

.roundOrangeBtn {
  border-radius: 10px;
  background-color: rgb(255, 120, 0);
  background-image: linear-gradient(to right, rgb(3, 151, 40), rgb(0, 231, 77));
  color: white;
  border: none;
  padding: 8px;
  margin-bottom: 8px;
}

.roundBlueBtn:hover,
.roundGreenBtn:hover {
  opacity: 0.85;
}

.largeText {
  font-size: large;
}

.horiz {
  display: flex;
  text-decoration: none;
  color: white;
  justify-content: center;
  align-items: center;
}

.horiz h1 {
  border-radius: 5px;
  font-size: 20px;
  /* padding: 14px 16px; */
}
.horiz :hover {
  background-color: rgb(64, 195, 255);
  /* color: black; */
}

.centerItems {
  align-items: center;
}
.affiliate {
  text-decoration: none;
  padding: 8px;
  color: #000f67;
}

.affiliate img {
  border-radius: 8px;
  margin-top: 8px;
  object-fit: cover;
  height: 100px;
}
.btn-lime {
  width: 100%;
  margin: 5px 0;
  display: inline-block;
  font-size: 17px;
  line-height: 20px;
  text-decoration: none; /* remove underline from anchors */
  box-sizing: border-box; /*Makes the button and inputs same width*/
  /* background-color: #4aeab8;
  color: white;
  cursor: pointer; */

  border: none;
  padding: 8px;
  color: white;
  border-radius: 10px;
  /* background-color: #00b800;
  background: #00b800; */
  background-color: #008e62;
  background: #008e62;
  box-shadow: 0 3px 3px rgb(0 0 0 / 30%);
  outline: none;
  cursor: pointer;
  font-weight: 600;
  text-decoration: none;
}

.btn-blue {
  width: 100%;
  font-size: 17px;
  line-height: 20px;
  text-decoration: none; /* remove underline from anchors */
  box-sizing: border-box; /*Makes the button and inputs same width*/
  /*Or use: * {
  box-sizing: border-box; /*Makes the button and inputs same width*/

  border: none;
  padding: 8px;
  color: white;
  border-radius: 10px;
  background-color: #0475cf;
  background: #0475cf;
  box-shadow: 0 3px 3px rgb(0 0 0 / 30%);
  outline: none;
  cursor: pointer;
  font-weight: 600;
  text-decoration: none;
}

input[name="email"],
.nEmail {
  padding-left: 50px;
  background-image: url("../images/email.png");
  background-position: left top;
  background-repeat: no-repeat;
  background-size: contain;
}
.point {
  cursor: pointer;
}
.smoke-pad {
  padding: 4px 12px;
}
.nameP {
  padding-left: 50px;
  background: url("../images/person.svg"),
    linear-gradient(to right, #21b1f3 50px, white 0%);
  background-position: left top;
  background-repeat: no-repeat;
  background-size: contain;
}

input[name="password"] {
  padding-left: 50px;
  background-image: url("../images/lock.png");
  background-position: left top;
  background-repeat: no-repeat;
  background-size: contain;
}

.center {
  width: 50%;
  margin-right: auto;
  margin-left: auto;
}
.absCenter {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
}
.abs-center-vert {
  top: 50%;
  transform: translateY(-50%);
}
.centerText {
  text-align: center;
}
.self-center {
  align-self: center;
}
.self-end {
  align-self: flex-end;
}
.self-start {
  align-self: flex-start;
}
.notes {
  color: lightslategray;
  font-size: 10px;
  margin-bottom: 8px;
}
.redText {
  color: #7a1a24;
  /* #7D0303 */
}
.loader {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 1;
  margin: -75px 0 0 -75px;
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
  display: none;
}

.loading {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 1;
  margin: -75px 0 0 -75px;
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

/* upload profile */
.img-circle {
  border-radius: 50%;
}
.circle-smoke-white {
  border-radius: 50%;
  background: #f5f5f5;
}
.circle-gray-border {
  border-radius: 50%;
  background: white;
  border: 1px solid #d6d6d6;
}
img.img-circle {
  background: white;
}
.redCircle {
  border-radius: 50%;
  background: linear-gradient(
    to bottom right,
    red,
    crimson,
    rgba(255, 0, 51, 0.815)
  );
}
.darkTransCircle {
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.747);
}
.lblUpload {
  left: 0;
  text-align: center;
  width: 100%;
  background: rgba(255, 255, 255, 0.5);
  background-color: #21b1f3; /* For browsers that do not support gradients */
  background-image: linear-gradient(to right, #e9383d, #21b1f3, #4aeab8);
  opacity: 0.8;
  bottom: 0;
  padding: 20px 0;
}
.img-thumbnail {
  border-radius: 0;
}
.img-thumbnail > div {
  display: inline-block;
  overflow: hidden;
  height: 140px;
  border: 5px solid #fff;
  -webkit-box-shadow: 0 2px 0 2px;
  box-shadow: 0 2px 0 2px #8a94a8;
  border-radius: 20%;
}

.hrtal {
  display: flex;
  align-items: center;
  overflow: hidden;
}
.ovf-hidden {
  overflow: hidden;
}
#downloadApp {
  z-index: 200;
}
.z-1 {
  z-index: 1;
}
.z-2 {
  z-index: 2;
}
#downloadApp a {
  text-decoration: none;
  text-align: center;
}

.btn-white {
  padding: 4px;
  border-radius: 4px;
  font-size: 17px;
  line-height: 20px;
  text-decoration: none;
  box-sizing: border-box;
  background-color: white;
  color: black;
  cursor: pointer;
}

.btn-white-border-top {
  border: none;
  padding: 4px;
  border-radius: 4px 4px 0px 0px;
  font-size: 17px;
  line-height: 20px;
  text-decoration: none;
  box-sizing: border-box;
  background-color: white;
  color: black;
  cursor: pointer;
}
.uploadImage {
  background: url("../images/upload_file.svg"),
    linear-gradient(to right, #21b1f3 50px, white 0%);
  background-position: left top;
  background-repeat: no-repeat;
  background-size: contain;
  /* background-color: #21B1F3; */

  cursor: pointer;
}

.blueBorder {
  width: 100%;
  padding: 12px;
  border: 2px solid #21b1f3;
  border-radius: 4px;
  margin: 5px 0;
  opacity: 0.85;
  display: inline-block;
  font-size: 17px;
  line-height: 20px;
  text-decoration: none; /* remove underline from anchors */
  box-sizing: border-box; /*Makes the button and inputs same width*/
  padding-left: 60px;
}

.grayBorder {
  width: 100%;
  padding: 12px;
  border: 2px solid #66696b;
  border-radius: 4px;
  margin: 5px 0;
  opacity: 0.85;
  display: inline-block;
  font-size: 17px;
  line-height: 20px;
  text-decoration: none; /* remove underline from anchors */
  box-sizing: border-box; /*Makes the button and inputs same width*/
  padding-left: 60px;
}
.grayBorder.contact {
  padding-left: 50px;
  background: url("../images/ic_at.svg"),
    linear-gradient(to right, #66696b 50px, white 0%);
  background-position: left top;
  background-repeat: no-repeat;
  background-size: contain;
}
.redBorder {
  width: 100%;
  padding: 12px;
  border: 2px solid #d82424;
  border-radius: 4px;
  margin: 5px 0;
  opacity: 0.85;
  display: inline-block;
  font-size: 17px;
  line-height: 20px;
  text-decoration: none; /* remove underline from anchors */
  box-sizing: border-box; /*Makes the button and inputs same width*/
  padding-left: 60px;
}
.blueBorderPad {
  width: 100%;
  padding: 12px;
  border: 2px solid #21b1f3;
  border-radius: 4px;
  margin: 5px 0;
  opacity: 0.85;
  display: inline-block;
  font-size: 17px;
  line-height: 20px;
  text-decoration: none; /* remove underline from anchors */
  box-sizing: border-box; /*Makes the button and inputs same width*/
}

.pickCountry {
  background: url("../images/location.svg"),
    linear-gradient(to right, #21b1f3 50px, white 0%);
  background-position: left top;
  background-repeat: no-repeat;
  background-size: contain;
}

.nPerson {
  background: url("../images/person.svg"),
    linear-gradient(to right, #21b1f3 50px, white 0%);
  background-position: left top;
  background-repeat: no-repeat;
  background-size: contain;
}

.nRadio {
  background: url("../images/radio.svg"),
    linear-gradient(to right, #21b1f3 50px, white 0%);
  background-position: left top;
  background-repeat: no-repeat;
  background-size: contain;
}

.nSlogan {
  background: url("../images/slogan.svg"),
    linear-gradient(to right, #21b1f3 50px, white 0%);
  background-position: left top;
  background-repeat: no-repeat;
  background-size: contain;
}

.nStream {
  background: url("../images/antenna.svg"),
    linear-gradient(to right, #21b1f3 50px, white 0%);
  background-position: left top;
  background-repeat: no-repeat;
  background-size: contain;
}

.nPass {
  background: url("../images/lock.svg"),
    linear-gradient(to right, #21b1f3 50px, white 0%);
  background-position: left top;
  background-repeat: no-repeat;
  background-size: contain;
}

.nPhone {
  background: url("../images/phone.svg"),
    linear-gradient(to right, #21b1f3 50px, white 0%);
  background-position: left top;
  background-repeat: no-repeat;
  background-size: contain;
}

.nEmail {
  background: url("../images/email.png"),
    linear-gradient(to right, #21b1f3 50px, white 0%);
  background-position: left top;
  background-repeat: no-repeat;
  background-size: contain;
}

.nWeb {
  background: url("../images/web.svg"),
    linear-gradient(to right, #21b1f3 50px, white 0%);
  background-position: left top;
  background-repeat: no-repeat;
  background-size: contain;
}

.mainTop {
  flex: 1;
}
.blueBkg {
  background-color: #21b1f3;
}
img {
  image-orientation: from-image;
}
.auto {
  overflow: auto;
}
a:focus,
a:hover {
  color: inherit;
  text-decoration: underline;
}
button:disabled,
button[disabled] {
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
}
.lblBlack {
  background-color: black;
  margin-bottom: 2px;
  text-align: center;
  width: 100%;
  display: block;
}
.lblBlack:hover {
  opacity: 0.85;
}
.version {
  text-align: end;
  flex: 1;
}
.text-end {
  text-align: end;
}
.noSelect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}
/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #dfdfdf;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #b5b5b5;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb:horizontal {
  background: #b5b5b5;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #2e89c5;
  background: linear-gradient(
    0deg,
    rgba(255, 0, 0, 1) 0%,
    rgba(255, 154, 0, 1) 10%,
    rgba(208, 222, 33, 1) 20%,
    rgba(79, 220, 74, 1) 30%,
    rgba(63, 218, 216, 1) 40%,
    rgba(47, 201, 226, 1) 50%,
    rgba(28, 127, 238, 1) 60%,
    rgba(95, 21, 242, 1) 70%,
    rgba(186, 12, 248, 1) 80%,
    rgba(251, 7, 217, 1) 90%,
    rgba(255, 0, 0, 1) 100%
  );
}

::-webkit-scrollbar-thumb:horizontal:hover {
  background: #21b1f3;

  background: linear-gradient(
    90deg,
    rgba(255, 0, 0, 1) 0%,
    rgba(255, 154, 0, 1) 10%,
    rgba(208, 222, 33, 1) 20%,
    rgba(79, 220, 74, 1) 30%,
    rgba(63, 218, 216, 1) 40%,
    rgba(47, 201, 226, 1) 50%,
    rgba(28, 127, 238, 1) 60%,
    rgba(95, 21, 242, 1) 70%,
    rgba(186, 12, 248, 1) 80%,
    rgba(251, 7, 217, 1) 90%,
    rgba(255, 0, 0, 1) 100%
  );
}

.roundWhiteTrans {
  border-radius: 10px;
  background-color: white;
  background: rgba(255, 255, 255, 0.5);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.roundRedTrans {
  border-radius: 10px;
  background-color: white;
  background: rgba(255, 0, 0, 0.5);
}
.circleBorderGray {
  border-radius: 50%;
  border: 2px solid gray;
  background-color: white;
}

.circleBorderBlue {
  border-radius: 50%;
  border: 2px solid #21b1f3;
  background-color: white;
}
.rgbRounded {
  border-radius: 4px;
  border: 2px solid;
  border-color: rgb(0, 183, 255) rgb(43, 252, 43) rgb(255, 22, 22) yellow;
  background-color: white;
}
.circle-white-scroll {
  border-radius: 50%;
  opacity: 0.5;
  border: 1px solid rgba(176, 176, 176);
  background-color: rgba(255, 255, 255);
}
.circle-white-scroll:hover {
  border-radius: 50%;
  opacity: 1;
  border: 1px solid rgb(176, 176, 176);
  background-color: rgb(255, 255, 255);
}
.leftScr,
.rightScr {
  position: absolute;
  top: 50%;
  background-color: #21b1f3a8;
  color: white;
  font-weight: bolder;
  padding: 8px;
  border-radius: 4px;
  transform: translateY(-50%);
  display: none;
  user-select: none;
}

.rightScr {
  right: 0px;
  /* /transform: translate(-50%, -50%); */
}

.leftScr:hover,
.rightScr:hover {
  background-color: #21b1f3;
}
.opas:hover {
  opacity: 0.85;
}

.blue-shadow-round {
  border: none;
  padding: 8px;
  color: white;
  border-radius: 10px;
  background-color: #00b0f0;
  background: #00b0f0;
  box-shadow: 0 3px 3px rgb(0 0 0 / 20%);
  outline: none;
}

.red-shadow-round {
  border: none;
  padding: 8px;
  color: white;
  border-radius: 10px;
  background-color: #f00000;
  background: #f00000;
  box-shadow: 0 3px 3px rgb(0 0 0 / 20%);
  outline: none;
}

.org-shadow-round {
  border: none;
  padding: 8px;
  color: rgb(3, 0, 46);
  border-radius: 10px;
  background-color: #ffc000;
  background: #ffc000;
  box-shadow: 0 3px 3px rgb(0 0 0 / 20%);
  outline: none;
}

.white-shadow-text-round {
  background: white;
  box-shadow: 0 0 5px rgb(0 0 0 / 50%);
  border: none;
  padding: 14px 2px 14px 8px;
  border-radius: 4px;
}
.white-shadow-round-box {
  background: white;
  box-shadow: 0 0 2px rgb(0 0 0 / 50%);
  border: none;
  padding: 8px;
  border-radius: 4px;
}
.white-shadow-text-round-3 {
  background: white;
  box-shadow: 0 0 5px rgb(0 0 0 / 50%);
  border: none;
  padding: 14px 2px 14px 8px;
  border-radius: 4px 0px 4px 4px;
}
.white-bkg {
  background: white;
}
.white-shadow-btm-round {
  background: white;
  box-shadow: 0 2px 5px rgb(0 0 0 / 50%);
  border: none;
  padding: 14px 2px 14px 8px;
  border-radius: 4px;
  margin-bottom: 8px;
}
.light-blue-shadow-btm-round {
  background: white;
  box-shadow: 0 2px 5px rgb(181 225 255 / 50%);
  border: none;
  padding: 14px 2px 14px 8px;
  border-radius: 4px;
}
.green-shadow-round {
  border: none;
  padding: 8px;
  color: white;
  border-radius: 10px;
  /* background-color: #00b050;
  background: #00b050; */
  background-color: #003314;
  background: #003314;
  box-shadow: 0 3px 3px rgb(0 0 0 / 20%);
  outline: none;
}
.round-white-trans-bkg {
  border: none;
  padding: 8px;
  border-radius: 10px;
  background-color: #ffffffb0;
  background: #ffffffb0;
  box-shadow: 0 3px 3px rgb(0 0 0 / 20%);
  outline: none;
}
.circle-white {
  background: white;
  border-radius: 50%;
}
.posAbs {
  position: absolute;
}
.pos-full-h {
  position: absolute;
  top: 0;
  bottom: 0;
}
.abs-left {
  position: absolute;
  left: 0;
}
.abs-btm {
  position: absolute;
  bottom: 0;
}
.abs-right {
  position: absolute;
  right: 0;
}
.rgb-border-btm {
  border-bottom: 1px solid white;
  border-image: linear-gradient(to right, #27c7fe, #ed5565, #a0d468) 30;
  border-width: 1px;
  border-style: solid;
}
.border-blue-shadow-round {
  border: 1px solid #b9e9ff;
  padding: 8px;
  color: #002e57;
  border-radius: 10px;
  background-color: white;
  background: white;
  box-shadow: 0 3px 3px rgb(1 150 255 / 20%);
  outline: none;
}
.light-blue-stripes {
  background-image: linear-gradient(
    45deg,
    #b2daff 25%,
    #f7f7f7 25%,
    #f7f7f7 50%,
    #b2daff 50%,
    #b2daff 75%,
    #f7f7f7 75%,
    #f7f7f7 100%
  );
}
.space-btw {
  justify-content: space-between;
}
.space-end {
  justify-content: flex-end;
}
.space-even {
  justify-content: space-evenly;
}
.btn-white-round-right {
  border-radius: 0 10px 10px 0;
  background: #ffffff;
  outline: none;
  cursor: pointer;
  box-shadow: 0px 0px 3px rgb(0 0 0 / 50%);
  color: #093eff;
  align-self: center;
  border: none;
}
.btn-white-round-left {
  border-radius: 10px 0px 0px 10px;
  background: white;
  outline: none;
  cursor: pointer;
  box-shadow: 0 0px 3px rgb(0 0 0 / 50%);
  color: #093eff;
  align-self: center;
  border: none;
}
.justify-center,
.j-center {
  justify-content: center;
}
.errNote {
  color: red;
  font-weight: bold;
  display: none;
}

p {
  margin: 10px 0;
}

.h-item {
  white-space: nowrap;
  margin-right: 8px;
  margin-bottom: 8px;
}
.v-item,
.mb8 {
  margin-bottom: 8px;
}
.mt8 {
  margin-top: 8px;
}
.wrap-any {
  overflow-wrap: anywhere;
}
.pSubTitle {
  color: #333;
  font-weight: bold;
  font-size: medium;
  margin: 8px 0;
}
.light-green-bkg {
  background: #e2f0d9;
  padding: 8px;
}

.light-red-bkg {
  background: #fbe5d6;
  padding: 8px;
}

.light-ylow-bkg {
  background: #fff2cc;
  padding: 8px;
}

.light-sky-blue-bkg {
  background: #d7f8ff;
  padding: 8px;
}

.light-blue-bkg {
  background: #e1f7ff;
  padding: 8px;
}

.red-bkg {
  background: red;
  color: white;
}

.green-bkg {
  background: #009612;
  color: white;
}

.orange-bkg {
  background: #ff4600;
  color: white;
}

.blue-bkg {
  background: #379aff;
  color: white;
}

.dk-green-bkg {
  background: #116d00;
  color: white;
}

.dk-blue-bkg {
  background: #00f;
  color: white;
}

.btn-blue-shadow-round {
  border: none;
  padding: 8px;
  color: white;
  border-radius: 10px;
  background-color: #093eff;
  background: #093eff;
  box-shadow: 0 3px 3px rgb(0 0 0 / 30%);
  outline: none;
  cursor: pointer;
  font-weight: 600;
  text-decoration: none;
}
.btn-m-blue-shadow-round {
  border: none;
  padding: 8px;
  color: white;
  border-radius: 10px;
  background-color: #076fed;
  background: #076fed;
  box-shadow: 0 3px 3px rgb(0 0 0 / 30%);
  outline: none;
  cursor: pointer;
  font-weight: 600;
  text-decoration: none;
}
.btn-circle-blue {
  border: none;
  border-radius: 50%;
  background: #093eff;
  outline: none;
  cursor: pointer;
  box-shadow: 0 3px 3px rgb(0 0 0 / 30%);
  color: white;
  font-weight: 600;
}
.btn-circle-light-blue {
  border: none;
  border-radius: 50%;
  background: #21b1f3;
  outline: none;
  cursor: pointer;
  box-shadow: 0 3px 3px rgb(0 0 0 / 30%);
  color: white;
  font-weight: 600;
  text-decoration: none;
}
.wh33 {
  width: 33px;
  height: 33px;
}
.btn-und-blue-shadow-round {
  border: 1px solid #21b1f3;
  padding: 4px;
  color: #002e57;
  border-radius: 8px;
  background-color: white;
  background: white;
  box-shadow: 0 3px 3px rgb(0 0 0 / 30%);
  outline: none;
  cursor: pointer;
  font-weight: 600;
  text-decoration: none;
}
.btn-dark-purple-shadow-round {
  border: none;
  padding: 8px;
  color: white;
  border-radius: 10px;
  background-color: #3e09ff;
  background: #3e09ff;
  box-shadow: 0 3px 3px rgb(0 0 0 / 30%);
  outline: none;
  cursor: pointer;
  font-weight: 600;
  text-decoration: none;
}
.btn-purple-shadow-round {
  border: none;
  padding: 8px;
  color: white;
  border-radius: 10px;
  background-color: #a109ff;
  background: #a109ff;
  box-shadow: 0 3px 3px rgb(0 0 0 / 30%);
  outline: none;
  cursor: pointer;
  font-weight: 600;
  text-decoration: none;
}
.btn-white-shadow-round {
  border: none;
  padding: 8px;
  color: #002e57;
  border: 1px solid #21b1f3;
  border-radius: 10px;
  background-color: white;
  background: white;
  box-shadow: 0 3px 3px rgb(0 0 0 / 30%);
  outline: none;
  cursor: pointer;
  font-weight: 600;
  text-decoration: none;
}
.round-white-red-glow {
  border-radius: 10px;
  background-color: white;
  background: rgb(255, 255, 255);
  box-shadow: 0 4px 8px 0 #ffbdbd, 0 6px 20px 0#FFBDBD;
  /* box-shadow: 0 3px 3px #FFBDBD; */
}
.round-white-blue-glow {
  border-radius: 10px;
  background-color: white;
  background: rgb(255, 255, 255);
  box-shadow: 0 4px 8px #58a2ff, 0 6px 20px #58a2ff;
  /* box-shadow: 0 3px 3px #FFBDBD; */
}
.btn-pink-shadow-round {
  border: none;
  padding: 8px;
  color: white;
  border-radius: 10px;
  background-color: #fe0aff;
  background: #fe0aff;
  box-shadow: 0 3px 3px rgb(0 0 0 / 30%);
  outline: none;
  cursor: pointer;
  font-weight: 600;
  text-decoration: none;
}
.btn-orgn-pink-shadow-round {
  border: none;
  padding: 8px;
  color: white;
  border-radius: 10px;
  background-color: #a10846;
  background: #a10846;
  box-shadow: 0 3px 3px rgb(0 0 0 / 30%);
  outline: none;
  cursor: pointer;
  font-weight: 600;
  text-decoration: none;
}
.btn-red-shadow-round {
  border: none;
  padding: 8px;
  color: white;
  border-radius: 10px;
  background-color: #990300;
  background: #990300;
  box-shadow: 0 3px 3px rgb(0 0 0 / 30%);
  outline: none;
  cursor: pointer;
  font-weight: 600;
  text-decoration: none;
}
.btn-orange-shadow-round {
  border: none;
  padding: 8px;
  color: white;
  border-radius: 10px;
  background-color: #ff850b;
  background: #ff850b;
  box-shadow: 0 3px 3px rgb(0 0 0 / 30%);
  outline: none;
  cursor: pointer;
  font-weight: 600;
  text-decoration: none;
}
.btn-yellow-shadow-round {
  border: none;
  padding: 8px;
  color: rgb(0, 3, 36);
  border-radius: 10px;
  background-color: #ffd60b;
  background: #ffd60b;
  box-shadow: 0 3px 3px rgb(0 0 0 / 30%);
  outline: none;
  cursor: pointer;
  font-weight: 600;
  text-decoration: none;
}

.btn-green-shadow-round {
  border: none;
  padding: 8px;
  color: white;
  border-radius: 10px;
  /* background-color: #00b800;
  background: #00b800; */
  background-color: #003314;
  background: #003314;
  box-shadow: 0 3px 3px rgb(0 0 0 / 30%);
  outline: none;
  cursor: pointer;
  font-weight: 600;
  text-decoration: none;
}

.btn-light-blue-shadow-round {
  border: none;
  padding: 8px;
  color: white;
  border-radius: 10px;
  background-color: #007cb5;
  background: #007cb5;
  box-shadow: 0 3px 3px rgb(0 0 0 / 30%);
  outline: none;
  cursor: pointer;
  font-weight: 600;
  text-decoration: none;
}

.btn-border-blue-w-end {
  border: 1px solid #5d9cec;
  padding: 8px;
  color: white;
  border-radius: 10px;
  background-color: #1753a3;
  background: #1753a3 url("../images/s_right.svg") right no-repeat;
  box-shadow: 0 3px 3px rgb(0 0 0 / 30%);
  outline: none;
  cursor: pointer;
  font-weight: 600;
  text-decoration: none;
  background-size: contain;
  padding-right: 4px;
}
.txt-blue {
  color: #1753a3;
}
.btn-border-purple-p-end {
  border: 1px solid #5d4c87;
  padding: 8px;
  color: white;
  border-radius: 10px;
  background-color: white;
  background: white url("../images/s_right_purple.svg") right no-repeat;
  box-shadow: 0 3px 3px rgb(0 0 0 / 30%);
  outline: none;
  cursor: pointer;
  font-weight: 600;
  text-decoration: none;
  background-size: contain;
  padding-right: 4px;
}

.btn-white-purple-border-sh {
  margin: 8px;
  border: none;
  color: black;
  border-radius: 10px;
  background: #fcfcfc;
  box-shadow: 0 3px 3px rgb(70 0 255 / 30%);
  outline: none;
  cursor: pointer;
  font-weight: 600;
  text-decoration: none;
  border: 1px solid #ddd;
}
.btn-white-red-border-sh {
  margin: 8px;
  border: none;
  color: black;
  border-radius: 10px;
  background: #fcfcfc;
  box-shadow: 0 3px 3px rgba(255, 0, 0, 0.3);
  outline: none;
  cursor: pointer;
  font-weight: 600;
  text-decoration: none;
  border: 1px solid #ddd;
}
.btn-white-orange-border-sh {
  margin: 8px;
  border: none;
  color: black;
  border-radius: 10px;
  background: #fcfcfc;
  box-shadow: 0 3px 3px rgba(255, 102, 0, 0.3);
  outline: none;
  cursor: pointer;
  font-weight: 600;
  text-decoration: none;
  border: 1px solid #ddd;
}
.btn-white-yellow-border-sh {
  margin: 8px;
  border: none;
  color: black;
  border-radius: 10px;
  background: #fcfcfc;
  box-shadow: 0 3px 3px rgba(255, 208, 0, 0.3);
  outline: none;
  cursor: pointer;
  font-weight: 600;
  text-decoration: none;
  border: 1px solid #ddd;
}
.btn-white-green-border-sh {
  margin: 8px;
  border: none;
  color: black;
  border-radius: 10px;
  background: #fcfcfc;
  box-shadow: 0 3px 3px rgba(0, 255, 81, 0.3);
  outline: none;
  cursor: pointer;
  font-weight: 600;
  text-decoration: none;
  border: 1px solid #ddd;
}
.btn-white-blue-border-sh {
  margin: 8px;
  border: none;
  color: black;
  border-radius: 10px;
  background: #fcfcfc;
  box-shadow: 0 3px 3px rgba(0, 157, 255, 0.3);
  outline: none;
  cursor: pointer;
  font-weight: 600;
  text-decoration: none;
  border: 1px solid #ddd;
}
.txt-purple {
  color: #4b0082;
}
.ml8 {
  margin-left: 8px;
}
/* .w-end::after{
  content: url("../images/left_space_point.svg");
} */
.pTitle {
  color: #000b4a;
  font-weight: bold;
  font-size: large;
  margin: unset;
}
.w100 {
  width: 100%;
}
.m8 {
  margin: 8px;
}
.grayInput {
  width: 100%;
  padding: 12px;
  border: 2px solid #66696b;
  border-radius: 4px;
  margin: 5px 0;
  opacity: 0.85;
  display: inline-block;
  font-size: 17px;
  line-height: 20px;
  text-decoration: none; /* remove underline from anchors */
  box-sizing: border-box; /*Makes the button and inputs same width*/
}
textarea {
  resize: vertical;
}
.capFirst {
  text-transform: capitalize;
}
.wh24 {
  width: 24px;
  height: 24px;
}
.f24 {
  font-size: 24px;
}
.mr8 {
  margin-right: 8px;
}
.mw64 {
  min-width: 64px;
}
.oneLine {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.pad8 {
  padding: 8px;
}
.pad4 {
  padding: 4px;
}
.pad0 {
  padding: 0px;
}
.align-normal {
  align-self: normal;
}
.red-filter {
  filter: brightness(0) saturate(100%) invert(13%) sepia(74%) saturate(2827%)
    hue-rotate(338deg) brightness(85%) contrast(93%);
}
.blue-filter {
  filter: brightness(0) saturate(100%) invert(20%) sepia(95%) saturate(1363%)
    hue-rotate(197deg) brightness(102%) contrast(92%);
}
.purple-filter {
  filter: brightness(0) saturate(100%) invert(31%) sepia(8%) saturate(2988%)
    hue-rotate(216deg) brightness(98%) contrast(91%);
}
.green-filter {
  filter: brightness(0) saturate(100%) invert(44%) sepia(9%) saturate(2790%)
    hue-rotate(46deg) brightness(92%) contrast(74%);
}
.d-green-text {
  color: #5a7c35;
}
.d-blue-text {
  color: #1753a3;
}
.d-purple-text {
  color: #5d4c87;
}
.dl-blue-text {
  color: #1c6bfa;
}
.white-btm-shadow {
  background: white;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.4);
}
.l-gray {
  background: #ededed;
}
label {
  font-weight: normal;
}
.t-title {
  font-weight: 600;
}
.btn-close {
  border: none;
  padding: 8px 14px;
  font-weight: 600;
  border-radius: 50%;
  background: rgb(0 0 0 / 8%);
  align-self: center;
}
.round-trans-bkg {
  background-color: #cce0e0e0;
  border-radius: 14px;
}
.btn-red-round-shadow {
  color: white;
  background: #990300;
  border: none;
  border-radius: 4px;
  padding: 4px 18px;
  font-weight: 600;
  box-shadow: 0 3px 3px rgb(0 0 0 / 30%);
}
.grid-3 {
  display: grid;
  grid-template-columns: auto auto auto;
  align-items: center;
}
.btm-top-gray-border {
  border-top: 1px solid #adabab;
  border-bottom: 1px solid #adabab;
  background: aliceblue;
}
.h100 {
  height: 100%;
}
.grid-2 {
  display: grid;
  grid-template-columns: min-content auto;
  grid-row-gap: 8px;
  grid-column-gap: 8px;
  grid-auto-rows: min-content;
}
.top-round-snow {
  border-radius: 4px 4px 0px 0px;
  padding: 8px;
  background: snow;
}

.gray-border-light {
  padding: 4px;
  border: 2px solid #cdcecf;
  border-radius: 4px;
  margin: 5px 0;
  opacity: 0.85;
  display: inline-block;
  font-size: 17px;
  line-height: 20px;
  text-decoration: none; /* remove underline from anchors */
  box-sizing: border-box; /*Makes the button and inputs same width*/
}
.dialog-white-trans-round {
  width: fit-content;
  height: fit-content;
  text-align: center;
  padding: 8px;
  background: linear-gradient(
    to right bottom,
    rgba(255, 255, 255),
    rgba(255, 255, 255),
    rgba(255, 255, 255, 0.3)
  );
  border-radius: 14px;
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
  overflow: auto;
}
.btn-margin button {
  margin-right: 8px;
}
a:hover {
  text-decoration: none;
  color: inherit;
}
.round-white-shadow {
  border-radius: 8px;
  /* background: rgb(241, 241, 131); */
  background-color: white;
  background: white;
  box-shadow: 0 3px 3px rgb(0 0 0 / 30%);
}
.br8 {
  border-radius: 8px;
}
.btn-und-green-shadow-round {
  border: 1px solid #08b310;
  padding: 4px;
  color: #005700;
  border-radius: 8px;
  background-color: white;
  background: white;
  box-shadow: 0 3px 3px rgb(0 0 0 / 30%);
  outline: none;
  cursor: pointer;
  font-weight: 600;
  text-decoration: none;
}

.btn-red-und-shadow-round {
  border: 1px solid #990300;
  padding: 8px;
  color: #990300;
  border-radius: 10px;
  background: white;
  /* box-shadow: 0 3px 3px rgb(0 0 0 / 30%); */
  outline: none;
  cursor: pointer;
  font-weight: 600;
  text-decoration: none;
}
.w24 {
  width: 24px;
}
.res-w-50 {
  width: 50%;
}
.font-12 {
  font-size: 12px;
}
.font-14 {
  font-size: 14px;
}
.font-16 {
  font-size: 16px;
}
.dark-rnb {
  background-color: rgb(0, 110, 255);
  background: linear-gradient(to right, #337900, #006492, #aa0075);
  color: white;
}
.close.white-circle-red {
  color: #ac1028;
  align-self: center;
  font-size: 28px;
  font-weight: bold;
  border-radius: 50%;
  background: white;
  padding: 2px 8px;
}
.border-blue-input {
  border: 1px solid rgb(0, 170, 255);
  border-radius: 4px;
  padding: 4px;
}
.border-blue-input:focus {
  border-style: none;
  outline: none;
  border: 1px solid #0478ed;
}
.no-border {
  border: none;
  border-style: none;
  outline: none;
}
.no-border:focus {
  border-style: none;
  outline: none;
  border: none;
  border-bottom: 1px solid #dcebf9;
}
input[type="checkbox"] {
  margin: 0px;
}
.d-s-blue-text {
  color: #483d8b;
}
.w-auto {
  width: auto;
}
.w-80p {
  width: 80%;
}
.c-m-blue {
  color: #3f62b5;
}
.bg-m-blue {
  background: #3f62b5;
}
.purple-glow {
  box-shadow: 0px 0px 4px #1455e4;
}
.c-cyan {
  color: #00748b;
}
.white-flat-blue-shadow {
  background: white;
  padding: 8px;
  margin-bottom: 8px;
  box-shadow: 3px 3px 4px #53b7e4;
}
.wRes80 {
  width: 80%;
}
@media screen and (max-width: 800px) {
  .modal-content,
  .center {
    width: 90%;
  }
  .vRes {
    flex-direction: column;
  }

  .hRes {
    flex-direction: row;
  }
  .btn-margin.vRes button {
    margin-top: 8px;
    min-width: 50%;
  }
  .res-w-50 {
    width: 90%;
  }
  .vRes .mr8 {
    margin-right: unset;
    margin-bottom: 8px;
  }
  .wRes80 {
    width: 100%;
  }
}
