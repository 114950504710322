html, body {
  height: 100%;
  margin: auto;
  font-family: Mulish, Segoe UI, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
}

body {
  background: #fff;
  margin-left: auto;
  margin-right: auto;
}

header {
  color: #fff;
  background: #fff linear-gradient(to right, #9fd17b, #40c3ff, #fe7be6);
  height: 50px;
  box-shadow: 0 4px 8px #0003, 0 6px 20px #00000030;
}

footer {
  color: #fff;
  background: #333 linear-gradient(to right, #00449c, #27c7fe);
  padding: 10px;
  font-size: small;
  display: flex;
}

.modal {
  z-index: 4;
  background-color: #000000be;
  flex-direction: column;
  width: 100%;
  height: 100%;
  display: none;
  position: absolute;
  top: 0;
  left: 0;
}

.modal-content {
  background-color: #fff;
  border: 1px solid #888;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 50%;
  max-height: 500px;
  margin: auto;
  padding: 20px;
  font-size: 16px;
  display: flex;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
}

.keep-width {
  flex-shrink: 0;
}

.close {
  color: #3d3d3d;
  float: right;
  opacity: .9;
  align-self: flex-end;
  font-size: 28px;
  font-weight: bold;
}

.close:hover, .close:focus {
  color: #000;
  cursor: pointer;
  text-decoration: none;
}

.btnBlue {
  color: #fff;
  cursor: pointer;
  background: #21b1f3;
  border: none;
  border-radius: 5px;
  width: 90%;
  padding: 12px;
  font-size: 16px;
}

.redBtn {
  color: #fff;
  background-color: red;
  background-image: linear-gradient(to right, #860000, red);
  border: none;
  border-radius: 5px;
  width: 90%;
  padding: 8px;
  font-size: 16px;
  text-decoration: none;
}

.blueBtn {
  color: #fff;
  background-color: red;
  background-image: linear-gradient(to right, #280086, #006eff);
  border: none;
  border-radius: 5px;
  padding: 8px;
  font-size: 16px;
  text-decoration: none;
}

.vRedBtn {
  color: #fff;
  background-color: red;
  background-image: linear-gradient(to right, #600086, #f02);
  border: none;
  border-radius: 5px;
  width: 90%;
  padding: 8px;
  font-size: 16px;
  text-decoration: none;
}

.orangeRedBtn {
  color: #fff;
  background-color: #ff4800;
  background-image: linear-gradient(to right, #f02, #ff4800);
  border: none;
  border-radius: 5px;
  width: 90%;
  padding: 8px;
  font-size: 16px;
  text-decoration: none;
}

.redBtn:hover {
  opacity: .85;
}

.btnBlueSmall {
  color: #fff;
  cursor: pointer;
  background: #21b1f3;
  border: none;
  border-radius: 5px;
  width: 30px;
  height: auto;
  padding: 5px;
  font-size: 16px;
}

.font-18 {
  font-size: 18px;
}

.rel, .posRel {
  position: relative;
}

.btnGray {
  color: #fff;
  cursor: pointer;
  background: #aaa;
  border: none;
  border-radius: 5px;
  width: 90%;
  padding: 12px;
  font-size: 16px;
}

.btn-gray {
  color: #fff;
  cursor: pointer;
  background: #aaa;
  border: none;
  border-radius: 5px;
  padding: 12px;
  font-size: 16px;
}

.btnBlue:hover, .btnGray:hover, .btn-gray:hover {
  opacity: .85;
}

.blueText {
  color: #2183f3;
}

.whiteText {
  color: #fff;
}

.boldText, .t-bold {
  font-weight: 600;
}

.grayText {
  color: gray;
}

.darkGrayText {
  color: #464646;
}

.pinkRedText {
  color: #dc143c;
}

#snackbar {
  visibility: hidden;
  color: #fff;
  text-align: center;
  z-index: 201;
  background-color: #333;
  border-radius: 2px;
  min-width: 250px;
  margin-left: -125px;
  padding: 16px;
  font-size: 17px;
  position: fixed;
  bottom: 30px;
  left: 50%;
}

#snackbar.show {
  visibility: visible;
  animation: .5s fadein, .5s 5.5s fadeout;
}

#toast {
  visibility: hidden;
  color: #fff;
  text-align: center;
  z-index: 201;
  background-color: #333333d3;
  border-radius: 25px;
  min-width: 250px;
  padding: 16px;
  font-size: 17px;
  position: fixed;
  bottom: 30px;
  left: 50%;
}

#toast.show {
  visibility: visible;
  animation: .5s fadein, .5s 5.5s fadeout;
}

.tooltip .tooltiptext {
  visibility: hidden;
  color: #fff;
  text-align: center;
  z-index: 1;
  opacity: 0;
  background-color: #555;
  border-radius: 6px;
  width: 140px;
  margin-left: -75px;
  padding: 5px;
  transition: opacity .3s;
  position: absolute;
  bottom: 150%;
  left: 50%;
}

.tooltip .tooltiptext:after {
  content: "";
  border: 5px solid #0000;
  border-top-color: #555;
  margin-left: -5px;
  position: absolute;
  top: 100%;
  left: 50%;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

@keyframes fadein {
  from {
    opacity: 0;
    bottom: 0;
  }

  to {
    opacity: 1;
    bottom: 30px;
  }
}

@keyframes fadeout {
  from {
    opacity: 1;
    bottom: 30px;
  }

  to {
    opacity: 0;
    bottom: 0;
  }
}

.bigText {
  font-size: xx-large;
}

.xFont {
  text-align: center;
  font-size: x-large;
}

.blueInput {
  opacity: .85;
  box-sizing: border-box;
  border: 2px solid #21b1f3;
  border-radius: 4px;
  width: 100%;
  margin: 5px 0;
  padding: 12px;
  font-size: 17px;
  line-height: 20px;
  text-decoration: none;
  display: inline-block;
}

.blueInput:hover, .btn-blue:hover, .btn-lime:hover {
  opacity: .85;
}

.greenText {
  color: #008cff;
  text-shadow: 2px 2px 4px #000;
}

.roundImg {
  border-radius: 50%;
}

.verticalFlex {
  flex-direction: column;
  display: flex;
}

.roundCorners {
  border-radius: 5px;
}

.horizFlex {
  flex-direction: row;
  display: flex;
}

.topRoundedWhite {
  background-color: #fff;
  border-radius: 10px 10px 0 0;
}

.roundBlueBtn {
  color: #fff;
  cursor: pointer;
  background-color: #21b1f3;
  background-image: linear-gradient(to right, #069ef3, #0084d4);
  border: none;
  border-radius: 8px;
  padding: 2px 8px;
}

.roundGreenBtn {
  color: #fff;
  background-color: #ff7800;
  background-image: linear-gradient(to right, #ff6c00, #ffbf00);
  border-radius: 10px;
}

.roundOrangeBtn {
  color: #fff;
  background-color: #ff7800;
  background-image: linear-gradient(to right, #039728, #00e74d);
  border: none;
  border-radius: 10px;
  margin-bottom: 8px;
  padding: 8px;
}

.roundBlueBtn:hover, .roundGreenBtn:hover {
  opacity: .85;
}

.largeText {
  font-size: large;
}

.horiz {
  color: #fff;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  display: flex;
}

.horiz h1 {
  border-radius: 5px;
  font-size: 20px;
}

.horiz :hover {
  background-color: #40c3ff;
}

.centerItems {
  align-items: center;
}

.affiliate {
  color: #000f67;
  padding: 8px;
  text-decoration: none;
}

.affiliate img {
  object-fit: cover;
  border-radius: 8px;
  height: 100px;
  margin-top: 8px;
}

.btn-lime {
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  background: #008e62;
  border: none;
  border-radius: 10px;
  outline: none;
  width: 100%;
  margin: 5px 0;
  padding: 8px;
  font-size: 17px;
  font-weight: 600;
  line-height: 20px;
  text-decoration: none;
  display: inline-block;
  box-shadow: 0 3px 3px #0000004d;
}

.btn-blue {
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  background: #0475cf;
  border: none;
  border-radius: 10px;
  outline: none;
  width: 100%;
  padding: 8px;
  font-size: 17px;
  font-weight: 600;
  line-height: 20px;
  text-decoration: none;
  box-shadow: 0 3px 3px #0000004d;
}

input[name="email"], .nEmail {
  background-image: url("email.a1d4c5b2.png");
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: contain;
  padding-left: 50px;
}

.point {
  cursor: pointer;
}

.smoke-pad {
  padding: 4px 12px;
}

.nameP {
  background-color: #0000;
  background-image: url("person.95f40add.svg"), linear-gradient(to right, #21b1f3 50px, #fff 0%);
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: contain;
  background-attachment: scroll, scroll;
  background-origin: padding-box, padding-box;
  background-clip: border-box, border-box;
  padding-left: 50px;
}

input[name="password"] {
  background-image: url("lock.2e5c5d7b.png");
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: contain;
  padding-left: 50px;
}

.center {
  width: 50%;
  margin-left: auto;
  margin-right: auto;
}

.absCenter {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.abs-center-vert {
  top: 50%;
  transform: translateY(-50%);
}

.centerText {
  text-align: center;
}

.self-center {
  align-self: center;
}

.self-end {
  align-self: flex-end;
}

.self-start {
  align-self: flex-start;
}

.notes {
  color: #789;
  margin-bottom: 8px;
  font-size: 10px;
}

.redText {
  color: #7a1a24;
}

.loader {
  z-index: 1;
  border: 16px solid #f3f3f3;
  border-top-color: #3498db;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  margin: -75px 0 0 -75px;
  animation: 2s linear infinite spin;
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
}

.loading {
  z-index: 1;
  border: 16px solid #f3f3f3;
  border-top-color: #3498db;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  margin: -75px 0 0 -75px;
  animation: 2s linear infinite spin;
  position: absolute;
  top: 50%;
  left: 50%;
}

.img-circle {
  border-radius: 50%;
}

.circle-smoke-white {
  background: #f5f5f5;
  border-radius: 50%;
}

.circle-gray-border {
  background: #fff;
  border: 1px solid #d6d6d6;
  border-radius: 50%;
}

img.img-circle {
  background: #fff;
}

.redCircle {
  background: linear-gradient(to bottom right, red, #dc143c, #ff0033d0);
  border-radius: 50%;
}

.darkTransCircle {
  background-color: #000000be;
  border-radius: 50%;
}

.lblUpload {
  text-align: center;
  opacity: .8;
  background: #21b1f3 linear-gradient(to right, #e9383d, #21b1f3, #4aeab8);
  width: 100%;
  padding: 20px 0;
  bottom: 0;
  left: 0;
}

.img-thumbnail {
  border-radius: 0;
}

.img-thumbnail > div {
  -webkit-box-shadow: 0 2px 0 2px;
  border: 5px solid #fff;
  border-radius: 20%;
  height: 140px;
  display: inline-block;
  overflow: hidden;
  box-shadow: 0 2px 0 2px #8a94a8;
}

.hrtal {
  align-items: center;
  display: flex;
  overflow: hidden;
}

.ovf-hidden {
  overflow: hidden;
}

#downloadApp {
  z-index: 200;
}

.z-1 {
  z-index: 1;
}

.z-2 {
  z-index: 2;
}

#downloadApp a {
  text-align: center;
  text-decoration: none;
}

.btn-white {
  box-sizing: border-box;
  color: #000;
  cursor: pointer;
  background-color: #fff;
  border-radius: 4px;
  padding: 4px;
  font-size: 17px;
  line-height: 20px;
  text-decoration: none;
}

.btn-white-border-top {
  box-sizing: border-box;
  color: #000;
  cursor: pointer;
  background-color: #fff;
  border: none;
  border-radius: 4px 4px 0 0;
  padding: 4px;
  font-size: 17px;
  line-height: 20px;
  text-decoration: none;
}

.uploadImage {
  cursor: pointer;
  background-color: #0000;
  background-image: url("upload_file.06f3f514.svg"), linear-gradient(to right, #21b1f3 50px, #fff 0%);
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: contain;
  background-attachment: scroll, scroll;
  background-origin: padding-box, padding-box;
  background-clip: border-box, border-box;
}

.blueBorder {
  opacity: .85;
  box-sizing: border-box;
  border: 2px solid #21b1f3;
  border-radius: 4px;
  width: 100%;
  margin: 5px 0;
  padding: 12px 12px 12px 60px;
  font-size: 17px;
  line-height: 20px;
  text-decoration: none;
  display: inline-block;
}

.grayBorder {
  opacity: .85;
  box-sizing: border-box;
  border: 2px solid #66696b;
  border-radius: 4px;
  width: 100%;
  margin: 5px 0;
  padding: 12px 12px 12px 60px;
  font-size: 17px;
  line-height: 20px;
  text-decoration: none;
  display: inline-block;
}

.grayBorder.contact {
  background-color: #0000;
  background-image: url("ic_at.2546f624.svg"), linear-gradient(to right, #66696b 50px, #fff 0%);
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: contain;
  background-attachment: scroll, scroll;
  background-origin: padding-box, padding-box;
  background-clip: border-box, border-box;
  padding-left: 50px;
}

.redBorder {
  opacity: .85;
  box-sizing: border-box;
  border: 2px solid #d82424;
  border-radius: 4px;
  width: 100%;
  margin: 5px 0;
  padding: 12px 12px 12px 60px;
  font-size: 17px;
  line-height: 20px;
  text-decoration: none;
  display: inline-block;
}

.blueBorderPad {
  opacity: .85;
  box-sizing: border-box;
  border: 2px solid #21b1f3;
  border-radius: 4px;
  width: 100%;
  margin: 5px 0;
  padding: 12px;
  font-size: 17px;
  line-height: 20px;
  text-decoration: none;
  display: inline-block;
}

.pickCountry {
  background-color: #0000;
  background-image: url("location.278ba984.svg"), linear-gradient(to right, #21b1f3 50px, #fff 0%);
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: contain;
  background-attachment: scroll, scroll;
  background-origin: padding-box, padding-box;
  background-clip: border-box, border-box;
}

.nPerson {
  background-color: #0000;
  background-image: url("person.95f40add.svg"), linear-gradient(to right, #21b1f3 50px, #fff 0%);
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: contain;
  background-attachment: scroll, scroll;
  background-origin: padding-box, padding-box;
  background-clip: border-box, border-box;
}

.nRadio {
  background-color: #0000;
  background-image: url("radio.8cf154a2.svg"), linear-gradient(to right, #21b1f3 50px, #fff 0%);
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: contain;
  background-attachment: scroll, scroll;
  background-origin: padding-box, padding-box;
  background-clip: border-box, border-box;
}

.nSlogan {
  background-color: #0000;
  background-image: url("slogan.adfe2ef1.svg"), linear-gradient(to right, #21b1f3 50px, #fff 0%);
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: contain;
  background-attachment: scroll, scroll;
  background-origin: padding-box, padding-box;
  background-clip: border-box, border-box;
}

.nStream {
  background-color: #0000;
  background-image: url("antenna.92552c89.svg"), linear-gradient(to right, #21b1f3 50px, #fff 0%);
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: contain;
  background-attachment: scroll, scroll;
  background-origin: padding-box, padding-box;
  background-clip: border-box, border-box;
}

.nPass {
  background-color: #0000;
  background-image: url("lock.d9ffe9ba.svg"), linear-gradient(to right, #21b1f3 50px, #fff 0%);
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: contain;
  background-attachment: scroll, scroll;
  background-origin: padding-box, padding-box;
  background-clip: border-box, border-box;
}

.nPhone {
  background-color: #0000;
  background-image: url("phone.186810e9.svg"), linear-gradient(to right, #21b1f3 50px, #fff 0%);
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: contain;
  background-attachment: scroll, scroll;
  background-origin: padding-box, padding-box;
  background-clip: border-box, border-box;
}

.nEmail {
  background-color: #0000;
  background-image: url("email.a1d4c5b2.png"), linear-gradient(to right, #21b1f3 50px, #fff 0%);
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: contain;
  background-attachment: scroll, scroll;
  background-origin: padding-box, padding-box;
  background-clip: border-box, border-box;
}

.nWeb {
  background-color: #0000;
  background-image: url("web.2e58be0c.svg"), linear-gradient(to right, #21b1f3 50px, #fff 0%);
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: contain;
  background-attachment: scroll, scroll;
  background-origin: padding-box, padding-box;
  background-clip: border-box, border-box;
}

.mainTop {
  flex: 1;
}

.blueBkg {
  background-color: #21b1f3;
}

img {
  image-orientation: from-image;
}

.auto {
  overflow: auto;
}

a:focus, a:hover {
  color: inherit;
  text-decoration: underline;
}

button:disabled, button[disabled] {
  color: #666;
  background-color: #ccc;
  border: 1px solid #999;
}

.lblBlack {
  text-align: center;
  background-color: #000;
  width: 100%;
  margin-bottom: 2px;
  display: block;
}

.lblBlack:hover {
  opacity: .85;
}

.version {
  text-align: end;
  flex: 1;
}

.text-end {
  text-align: end;
}

.noSelect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  -khtml-user-select: none;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

::-webkit-scrollbar {
  width: 8px;
  height: 10px;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
  box-shadow: inset 0 0 5px #dfdfdf;
}

::-webkit-scrollbar-thumb {
  background: #b5b5b5;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:horizontal {
  background: #b5b5b5;
}

::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(0deg, red 0%, #ff9a00 10%, #d0de21 20%, #4fdc4a 30%, #3fdad8 40%, #2fc9e2 50%, #1c7fee 60%, #5f15f2 70%, #ba0cf8 80%, #fb07d9 90%, red 100%);
}

::-webkit-scrollbar-thumb:horizontal:hover {
  background: linear-gradient(90deg, red 0%, #ff9a00 10%, #d0de21 20%, #4fdc4a 30%, #3fdad8 40%, #2fc9e2 50%, #1c7fee 60%, #5f15f2 70%, #ba0cf8 80%, #fb07d9 90%, red 100%);
}

.roundWhiteTrans {
  background: #ffffff80;
  border-radius: 10px;
  box-shadow: 0 4px 8px #0003, 0 6px 20px #00000030;
}

.roundRedTrans {
  background: #ff000080;
  border-radius: 10px;
}

.circleBorderGray {
  background-color: #fff;
  border: 2px solid gray;
  border-radius: 50%;
}

.circleBorderBlue {
  background-color: #fff;
  border: 2px solid #21b1f3;
  border-radius: 50%;
}

.rgbRounded {
  background-color: #fff;
  border: 2px solid #00b7ff;
  border-color: #00b7ff #2bfc2b #ff1616 #ff0;
  border-radius: 4px;
}

.circle-white-scroll {
  opacity: .5;
  background-color: #fff;
  border: 1px solid #b0b0b0;
  border-radius: 50%;
}

.circle-white-scroll:hover {
  opacity: 1;
  background-color: #fff;
  border: 1px solid #b0b0b0;
  border-radius: 50%;
}

.leftScr, .rightScr {
  color: #fff;
  -webkit-user-select: none;
  user-select: none;
  background-color: #21b1f3a8;
  border-radius: 4px;
  padding: 8px;
  font-weight: bolder;
  display: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.rightScr {
  right: 0;
}

.leftScr:hover, .rightScr:hover {
  background-color: #21b1f3;
}

.opas:hover {
  opacity: .85;
}

.blue-shadow-round {
  color: #fff;
  background: #00b0f0;
  border: none;
  border-radius: 10px;
  outline: none;
  padding: 8px;
  box-shadow: 0 3px 3px #0003;
}

.red-shadow-round {
  color: #fff;
  background: #f00000;
  border: none;
  border-radius: 10px;
  outline: none;
  padding: 8px;
  box-shadow: 0 3px 3px #0003;
}

.org-shadow-round {
  color: #03002e;
  background: #ffc000;
  border: none;
  border-radius: 10px;
  outline: none;
  padding: 8px;
  box-shadow: 0 3px 3px #0003;
}

.white-shadow-text-round {
  background: #fff;
  border: none;
  border-radius: 4px;
  padding: 14px 2px 14px 8px;
  box-shadow: 0 0 5px #00000080;
}

.white-shadow-round-box {
  background: #fff;
  border: none;
  border-radius: 4px;
  padding: 8px;
  box-shadow: 0 0 2px #00000080;
}

.white-shadow-text-round-3 {
  background: #fff;
  border: none;
  border-radius: 4px 0 4px 4px;
  padding: 14px 2px 14px 8px;
  box-shadow: 0 0 5px #00000080;
}

.white-bkg {
  background: #fff;
}

.white-shadow-btm-round {
  background: #fff;
  border: none;
  border-radius: 4px;
  margin-bottom: 8px;
  padding: 14px 2px 14px 8px;
  box-shadow: 0 2px 5px #00000080;
}

.light-blue-shadow-btm-round {
  background: #fff;
  border: none;
  border-radius: 4px;
  padding: 14px 2px 14px 8px;
  box-shadow: 0 2px 5px #b5e1ff80;
}

.green-shadow-round {
  color: #fff;
  background: #003314;
  border: none;
  border-radius: 10px;
  outline: none;
  padding: 8px;
  box-shadow: 0 3px 3px #0003;
}

.round-white-trans-bkg {
  background: #ffffffb0;
  border: none;
  border-radius: 10px;
  outline: none;
  padding: 8px;
  box-shadow: 0 3px 3px #0003;
}

.circle-white {
  background: #fff;
  border-radius: 50%;
}

.posAbs {
  position: absolute;
}

.pos-full-h {
  position: absolute;
  top: 0;
  bottom: 0;
}

.abs-left {
  position: absolute;
  left: 0;
}

.abs-btm {
  position: absolute;
  bottom: 0;
}

.abs-right {
  position: absolute;
  right: 0;
}

.rgb-border-btm {
  border-style: solid;
  border-width: 1px;
  border-bottom-color: #fff;
  border-image: linear-gradient(to right, #27c7fe, #ed5565, #a0d468) 30;
}

.border-blue-shadow-round {
  color: #002e57;
  background: #fff;
  border: 1px solid #b9e9ff;
  border-radius: 10px;
  outline: none;
  padding: 8px;
  box-shadow: 0 3px 3px #0196ff33;
}

.light-blue-stripes {
  background-image: linear-gradient(45deg, #b2daff 25%, #f7f7f7 25% 50%, #b2daff 50% 75%, #f7f7f7 75% 100%);
}

.space-btw {
  justify-content: space-between;
}

.space-end {
  justify-content: flex-end;
}

.space-even {
  justify-content: space-evenly;
}

.btn-white-round-right {
  cursor: pointer;
  color: #093eff;
  background: #fff;
  border: none;
  border-radius: 0 10px 10px 0;
  outline: none;
  align-self: center;
  box-shadow: 0 0 3px #00000080;
}

.btn-white-round-left {
  cursor: pointer;
  color: #093eff;
  background: #fff;
  border: none;
  border-radius: 10px 0 0 10px;
  outline: none;
  align-self: center;
  box-shadow: 0 0 3px #00000080;
}

.justify-center, .j-center {
  justify-content: center;
}

.errNote {
  color: red;
  font-weight: bold;
  display: none;
}

p {
  margin: 10px 0;
}

.h-item {
  white-space: nowrap;
  margin-bottom: 8px;
  margin-right: 8px;
}

.v-item, .mb8 {
  margin-bottom: 8px;
}

.mt8 {
  margin-top: 8px;
}

.wrap-any {
  overflow-wrap: anywhere;
}

.pSubTitle {
  color: #333;
  margin: 8px 0;
  font-size: medium;
  font-weight: bold;
}

.light-green-bkg {
  background: #e2f0d9;
  padding: 8px;
}

.light-red-bkg {
  background: #fbe5d6;
  padding: 8px;
}

.light-ylow-bkg {
  background: #fff2cc;
  padding: 8px;
}

.light-sky-blue-bkg {
  background: #d7f8ff;
  padding: 8px;
}

.light-blue-bkg {
  background: #e1f7ff;
  padding: 8px;
}

.red-bkg {
  color: #fff;
  background: red;
}

.green-bkg {
  color: #fff;
  background: #009612;
}

.orange-bkg {
  color: #fff;
  background: #ff4600;
}

.blue-bkg {
  color: #fff;
  background: #379aff;
}

.dk-green-bkg {
  color: #fff;
  background: #116d00;
}

.dk-blue-bkg {
  color: #fff;
  background: #00f;
}

.btn-blue-shadow-round {
  color: #fff;
  cursor: pointer;
  background: #093eff;
  border: none;
  border-radius: 10px;
  outline: none;
  padding: 8px;
  font-weight: 600;
  text-decoration: none;
  box-shadow: 0 3px 3px #0000004d;
}

.btn-m-blue-shadow-round {
  color: #fff;
  cursor: pointer;
  background: #076fed;
  border: none;
  border-radius: 10px;
  outline: none;
  padding: 8px;
  font-weight: 600;
  text-decoration: none;
  box-shadow: 0 3px 3px #0000004d;
}

.btn-circle-blue {
  cursor: pointer;
  color: #fff;
  background: #093eff;
  border: none;
  border-radius: 50%;
  outline: none;
  font-weight: 600;
  box-shadow: 0 3px 3px #0000004d;
}

.btn-circle-light-blue {
  cursor: pointer;
  color: #fff;
  background: #21b1f3;
  border: none;
  border-radius: 50%;
  outline: none;
  font-weight: 600;
  text-decoration: none;
  box-shadow: 0 3px 3px #0000004d;
}

.wh33 {
  width: 33px;
  height: 33px;
}

.btn-und-blue-shadow-round {
  color: #002e57;
  cursor: pointer;
  background: #fff;
  border: 1px solid #21b1f3;
  border-radius: 8px;
  outline: none;
  padding: 4px;
  font-weight: 600;
  text-decoration: none;
  box-shadow: 0 3px 3px #0000004d;
}

.btn-dark-purple-shadow-round {
  color: #fff;
  cursor: pointer;
  background: #3e09ff;
  border: none;
  border-radius: 10px;
  outline: none;
  padding: 8px;
  font-weight: 600;
  text-decoration: none;
  box-shadow: 0 3px 3px #0000004d;
}

.btn-purple-shadow-round {
  color: #fff;
  cursor: pointer;
  background: #a109ff;
  border: none;
  border-radius: 10px;
  outline: none;
  padding: 8px;
  font-weight: 600;
  text-decoration: none;
  box-shadow: 0 3px 3px #0000004d;
}

.btn-white-shadow-round {
  color: #002e57;
  cursor: pointer;
  background: #fff;
  border: 1px solid #21b1f3;
  border-radius: 10px;
  outline: none;
  padding: 8px;
  font-weight: 600;
  text-decoration: none;
  box-shadow: 0 3px 3px #0000004d;
}

.round-white-red-glow {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px #ffbdbd, 0 6px 20px #ffbdbd;
}

.round-white-blue-glow {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px #58a2ff, 0 6px 20px #58a2ff;
}

.btn-pink-shadow-round {
  color: #fff;
  cursor: pointer;
  background: #fe0aff;
  border: none;
  border-radius: 10px;
  outline: none;
  padding: 8px;
  font-weight: 600;
  text-decoration: none;
  box-shadow: 0 3px 3px #0000004d;
}

.btn-orgn-pink-shadow-round {
  color: #fff;
  cursor: pointer;
  background: #a10846;
  border: none;
  border-radius: 10px;
  outline: none;
  padding: 8px;
  font-weight: 600;
  text-decoration: none;
  box-shadow: 0 3px 3px #0000004d;
}

.btn-red-shadow-round {
  color: #fff;
  cursor: pointer;
  background: #990300;
  border: none;
  border-radius: 10px;
  outline: none;
  padding: 8px;
  font-weight: 600;
  text-decoration: none;
  box-shadow: 0 3px 3px #0000004d;
}

.btn-orange-shadow-round {
  color: #fff;
  cursor: pointer;
  background: #ff850b;
  border: none;
  border-radius: 10px;
  outline: none;
  padding: 8px;
  font-weight: 600;
  text-decoration: none;
  box-shadow: 0 3px 3px #0000004d;
}

.btn-yellow-shadow-round {
  color: #000324;
  cursor: pointer;
  background: #ffd60b;
  border: none;
  border-radius: 10px;
  outline: none;
  padding: 8px;
  font-weight: 600;
  text-decoration: none;
  box-shadow: 0 3px 3px #0000004d;
}

.btn-green-shadow-round {
  color: #fff;
  cursor: pointer;
  background: #003314;
  border: none;
  border-radius: 10px;
  outline: none;
  padding: 8px;
  font-weight: 600;
  text-decoration: none;
  box-shadow: 0 3px 3px #0000004d;
}

.btn-light-blue-shadow-round {
  color: #fff;
  cursor: pointer;
  background: #007cb5;
  border: none;
  border-radius: 10px;
  outline: none;
  padding: 8px;
  font-weight: 600;
  text-decoration: none;
  box-shadow: 0 3px 3px #0000004d;
}

.btn-border-blue-w-end {
  color: #fff;
  cursor: pointer;
  background: #1753a3 url("s_right.84db772d.svg") 100% / contain no-repeat;
  border: 1px solid #5d9cec;
  border-radius: 10px;
  outline: none;
  padding: 8px 4px 8px 8px;
  font-weight: 600;
  text-decoration: none;
  box-shadow: 0 3px 3px #0000004d;
}

.txt-blue {
  color: #1753a3;
}

.btn-border-purple-p-end {
  color: #fff;
  cursor: pointer;
  background: #fff url("s_right_purple.f36c7f71.svg") 100% / contain no-repeat;
  border: 1px solid #5d4c87;
  border-radius: 10px;
  outline: none;
  padding: 8px 4px 8px 8px;
  font-weight: 600;
  text-decoration: none;
  box-shadow: 0 3px 3px #0000004d;
}

.btn-white-purple-border-sh {
  color: #000;
  cursor: pointer;
  background: #fcfcfc;
  border: 1px solid #ddd;
  border-radius: 10px;
  outline: none;
  margin: 8px;
  font-weight: 600;
  text-decoration: none;
  box-shadow: 0 3px 3px #4600ff4d;
}

.btn-white-red-border-sh {
  color: #000;
  cursor: pointer;
  background: #fcfcfc;
  border: 1px solid #ddd;
  border-radius: 10px;
  outline: none;
  margin: 8px;
  font-weight: 600;
  text-decoration: none;
  box-shadow: 0 3px 3px #ff00004d;
}

.btn-white-orange-border-sh {
  color: #000;
  cursor: pointer;
  background: #fcfcfc;
  border: 1px solid #ddd;
  border-radius: 10px;
  outline: none;
  margin: 8px;
  font-weight: 600;
  text-decoration: none;
  box-shadow: 0 3px 3px #ff66004d;
}

.btn-white-yellow-border-sh {
  color: #000;
  cursor: pointer;
  background: #fcfcfc;
  border: 1px solid #ddd;
  border-radius: 10px;
  outline: none;
  margin: 8px;
  font-weight: 600;
  text-decoration: none;
  box-shadow: 0 3px 3px #ffd0004d;
}

.btn-white-green-border-sh {
  color: #000;
  cursor: pointer;
  background: #fcfcfc;
  border: 1px solid #ddd;
  border-radius: 10px;
  outline: none;
  margin: 8px;
  font-weight: 600;
  text-decoration: none;
  box-shadow: 0 3px 3px #00ff514d;
}

.btn-white-blue-border-sh {
  color: #000;
  cursor: pointer;
  background: #fcfcfc;
  border: 1px solid #ddd;
  border-radius: 10px;
  outline: none;
  margin: 8px;
  font-weight: 600;
  text-decoration: none;
  box-shadow: 0 3px 3px #009dff4d;
}

.txt-purple {
  color: indigo;
}

.ml8 {
  margin-left: 8px;
}

.pTitle {
  color: #000b4a;
  margin: unset;
  font-size: large;
  font-weight: bold;
}

.w100 {
  width: 100%;
}

.m8 {
  margin: 8px;
}

.grayInput {
  opacity: .85;
  box-sizing: border-box;
  border: 2px solid #66696b;
  border-radius: 4px;
  width: 100%;
  margin: 5px 0;
  padding: 12px;
  font-size: 17px;
  line-height: 20px;
  text-decoration: none;
  display: inline-block;
}

textarea {
  resize: vertical;
}

.capFirst {
  text-transform: capitalize;
}

.wh24 {
  width: 24px;
  height: 24px;
}

.f24 {
  font-size: 24px;
}

.mr8 {
  margin-right: 8px;
}

.mw64 {
  min-width: 64px;
}

.oneLine {
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.pad8 {
  padding: 8px;
}

.pad4 {
  padding: 4px;
}

.pad0 {
  padding: 0;
}

.align-normal {
  align-self: normal;
}

.red-filter {
  filter: brightness(0) saturate() invert(13%) sepia(74%) saturate(2827%) hue-rotate(338deg) brightness(85%) contrast(93%);
}

.blue-filter {
  filter: brightness(0) saturate() invert(20%) sepia(95%) saturate(1363%) hue-rotate(197deg) brightness(102%) contrast(92%);
}

.purple-filter {
  filter: brightness(0) saturate() invert(31%) sepia(8%) saturate(2988%) hue-rotate(216deg) brightness(98%) contrast(91%);
}

.green-filter {
  filter: brightness(0) saturate() invert(44%) sepia(9%) saturate(2790%) hue-rotate(46deg) brightness(92%) contrast(74%);
}

.d-green-text {
  color: #5a7c35;
}

.d-blue-text {
  color: #1753a3;
}

.d-purple-text {
  color: #5d4c87;
}

.dl-blue-text {
  color: #1c6bfa;
}

.white-btm-shadow {
  background: #fff;
  box-shadow: 0 1px 4px #0006;
}

.l-gray {
  background: #ededed;
}

label {
  font-weight: normal;
}

.t-title {
  font-weight: 600;
}

.btn-close {
  background: #00000014;
  border: none;
  border-radius: 50%;
  align-self: center;
  padding: 8px 14px;
  font-weight: 600;
}

.round-trans-bkg {
  background-color: #cce0e0e0;
  border-radius: 14px;
}

.btn-red-round-shadow {
  color: #fff;
  background: #990300;
  border: none;
  border-radius: 4px;
  padding: 4px 18px;
  font-weight: 600;
  box-shadow: 0 3px 3px #0000004d;
}

.grid-3 {
  grid-template-columns: auto auto auto;
  align-items: center;
  display: grid;
}

.btm-top-gray-border {
  background: #f0f8ff;
  border-top: 1px solid #adabab;
  border-bottom: 1px solid #adabab;
}

.h100 {
  height: 100%;
}

.grid-2 {
  grid-row-gap: 8px;
  grid-column-gap: 8px;
  grid-template-columns: min-content auto;
  grid-auto-rows: min-content;
  display: grid;
}

.top-round-snow {
  background: snow;
  border-radius: 4px 4px 0 0;
  padding: 8px;
}

.gray-border-light {
  opacity: .85;
  box-sizing: border-box;
  border: 2px solid #cdcecf;
  border-radius: 4px;
  margin: 5px 0;
  padding: 4px;
  font-size: 17px;
  line-height: 20px;
  text-decoration: none;
  display: inline-block;
}

.dialog-white-trans-round {
  text-align: center;
  background: linear-gradient(to bottom right, #fff, #fff, #ffffff4d);
  border-radius: 14px;
  width: -moz-fit-content;
  width: fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: 8px;
  overflow: auto;
  box-shadow: 0 4px 8px #0003, 0 6px 20px #00000030;
}

.btn-margin button {
  margin-right: 8px;
}

a:hover {
  color: inherit;
  text-decoration: none;
}

.round-white-shadow {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 3px 3px #0000004d;
}

.br8 {
  border-radius: 8px;
}

.btn-und-green-shadow-round {
  color: #005700;
  cursor: pointer;
  background: #fff;
  border: 1px solid #08b310;
  border-radius: 8px;
  outline: none;
  padding: 4px;
  font-weight: 600;
  text-decoration: none;
  box-shadow: 0 3px 3px #0000004d;
}

.btn-red-und-shadow-round {
  color: #990300;
  cursor: pointer;
  background: #fff;
  border: 1px solid #990300;
  border-radius: 10px;
  outline: none;
  padding: 8px;
  font-weight: 600;
  text-decoration: none;
}

.w24 {
  width: 24px;
}

.res-w-50 {
  width: 50%;
}

.font-12 {
  font-size: 12px;
}

.font-14 {
  font-size: 14px;
}

.font-16 {
  font-size: 16px;
}

.dark-rnb {
  color: #fff;
  background: linear-gradient(to right, #337900, #006492, #aa0075);
}

.close.white-circle-red {
  color: #ac1028;
  background: #fff;
  border-radius: 50%;
  align-self: center;
  padding: 2px 8px;
  font-size: 28px;
  font-weight: bold;
}

.border-blue-input {
  border: 1px solid #0af;
  border-radius: 4px;
  padding: 4px;
}

.border-blue-input:focus {
  border: 1px solid #0478ed;
  outline: none;
}

.no-border {
  border: none;
  outline: none;
}

.no-border:focus {
  border: none;
  border-bottom: 1px solid #dcebf9;
  outline: none;
}

input[type="checkbox"] {
  margin: 0;
}

.d-s-blue-text {
  color: #483d8b;
}

.w-auto {
  width: auto;
}

.w-80p {
  width: 80%;
}

.c-m-blue {
  color: #3f62b5;
}

.bg-m-blue {
  background: #3f62b5;
}

.purple-glow {
  box-shadow: 0 0 4px #1455e4;
}

.c-cyan {
  color: #00748b;
}

.white-flat-blue-shadow {
  background: #fff;
  margin-bottom: 8px;
  padding: 8px;
  box-shadow: 3px 3px 4px #53b7e4;
}

.wRes80 {
  width: 80%;
}

@media screen and (width <= 800px) {
  .modal-content, .center {
    width: 90%;
  }

  .vRes {
    flex-direction: column;
  }

  .hRes {
    flex-direction: row;
  }

  .btn-margin.vRes button {
    min-width: 50%;
    margin-top: 8px;
  }

  .res-w-50 {
    width: 90%;
  }

  .vRes .mr8 {
    margin-right: unset;
    margin-bottom: 8px;
  }

  .wRes80 {
    width: 100%;
  }
}

/*# sourceMappingURL=index.08d59ce5.css.map */
